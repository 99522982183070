import Cookies from 'js-cookie'

const useCookie = (key) => {
    const cookieData = Cookies.get(key)

    const setCookie = (data, ...args) => {
        if (data === null || data === 'undefined') {
            Cookies.remove(key)
        } else {
            Cookies.set(key, data, ...args)
        }
    }

    return [cookieData, setCookie]
}

export default useCookie
